import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vxe_form = _resolveComponent("vxe-form")!
  const _component_vxe_button = _resolveComponent("vxe-button")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, _mergeProps({ ref: "grid" }, _ctx.gridOptions, {
    onRemoveSelectedRows: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideBatchDropDown())),
    onEdit: _ctx.onGridEdit
  }), {
    modal: _withCtx(({ row, submit, reset }) => [
      _createVNode(_component_vxe_form, _mergeProps({
        class: "ml-3",
        ref: "sessionForm"
      }, _ctx.formOptions, {
        data: row,
        onReset: reset,
        onSubmit: submit
      }), null, 16, ["data", "onReset", "onSubmit"])
    ]),
    "modal-footer": _withCtx(() => [
      _createVNode(_component_vxe_button, {
        type: "submit",
        status: "primary",
        content: "確認",
        onClick: _ctx.close
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 16, ["onEdit"]))
}